import React from "react";
import { StaticQuery, graphql } from "gatsby";
export default ({ children, className, dynamicStyles }) => {
  return (
    <StaticQuery
      query={graphql`
        query LoadSiteConfigContainer {
          contentfulSiteConfiguration {
            fonts
            colorHex
            backgroundImage {
              file {
                url
              }
            }
            footer {
              json
            }
            backgroundChoice
          }
        }
      `}
      render={(data) => {
        const config = data.contentfulSiteConfiguration;
        const style = {
          fontFamily: config.fonts,
        };
        if (data.backgroundChoice === "Color") {
          style.backgroundColor = config.colorHex;
        } else {
          style.backgroundImage = `url(${config.backgroundImage.file.url})`;
        }
        return (
          <div className={className} style={style}>
            {" "}
            {children}{" "}
          </div>
        );
      }}
    />
  );
};
