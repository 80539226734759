import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Container from "../components/container";
import Footer from "../components/footer";
import Helmet from "react-helmet";
// import favicon from "../images/favicon.ico";
import headerStyle from "./header.module.css";
import Header from "../components/header";
import "./base.css";
const Template = ({ children, className, imgSrc, dynamicStyles }) => {
  return (
    <Container
      className={`${headerStyle.container} ${className || ""}`}
      dynamicStyles={dynamicStyles}
    >
      <Header imgSrcPath={imgSrc} />
      <div className={headerStyle.maxWidthContainer}>
        <StaticQuery
          query={graphql`
            query LoadFavIcon {
              contentfulSiteConfiguration {
                favicon {
                  file {
                    url
                  }
                }
              }
            }
          `}
          render={(data) => {
            const config = data.contentfulSiteConfiguration;
            return (
              <Helmet>
                <meta content="device-width, initial-scale=1" name="viewport" />
                <link
                  rel="icon"
                  type="image/png"
                  href={config.favicon.file.url}
                />
              </Helmet>
            );
          }}
        />
        <div className={headerStyle.content}>{children}</div>
        <Footer dynamicStyles={dynamicStyles} />
      </div>
    </Container>
  );
};
export default Template;
