import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styles from "./footer.module.css";
import { Link } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
// const currentYear = () => new Date().getFullYear();
export default ({ dynamicStyles }) => {
  return (
    <StaticQuery
      query={graphql`
        query LoadSiteConfigFooter {
          contentfulSiteConfiguration {
            footerTheme
            fonts
            colorHex
            backgroundImage {
              file {
                url
              }
            }
            footer {
              json
            }
            backgroundChoice
          }
        }
      `}
      render={(data) => {
        const style = data.contentfulSiteConfiguration;
        const reducingWarnings = () => {
          return;
        };

        const footer = style.footer.json ? style.footer.json : "";
        return (
          <footer>
            <div className="section-headline"> </div>
            <div className={styles.footerContainer}>
              <div className={styles.left}>
                <div className={`${style.footerTheme}Footer`}>
                  <a
                    href="https://splice.com"
                    onFocus={reducingWarnings}
                    onBlur={reducingWarnings}
                  >
                    Splice.com{" "}
                  </a>
                  <Link
                    to="/terms"
                    onFocus={reducingWarnings}
                    onBlur={reducingWarnings}
                  >
                    {" "}
                    Terms of Use{" "}
                  </Link>
                </div>
              </div>
              <div className={styles.middle}></div>
              {/* <div className={styles.middle}>© {currentYear()}</div> */}
              <div className={styles.right}>
                <div className={`${style.footerTheme}Footer`}>
                  {documentToReactComponents(footer)}
                </div>
              </div>
            </div>
          </footer>
        );
      }}
    />
  );
};
